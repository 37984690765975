.app {
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
}

.top {
    flex: 0;
    border-bottom: solid 1px var(--adm-color-border);
}

.body {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottom {
    /* flex: 0; */
    width: 100%;
    padding: 6px 0;
    position: fixed;
    bottom: 0;
    right: 0;
    border-top: solid 1px var(--adm-color-border);
    z-index: 100;
    background-color: #ffffff;

}

.warm-theme {
    /* --adm-color-primary: #FFD000; */
    --adm-color-primary: #F0953F
}

.customIndicator {
    position: absolute;
    top: 6px;
    right: 6px;
    background: rgba(0, 0, 0, 0.3);
    padding: 4px 8px;
    color: #ffffff;
    border-radius: 4px;
    user-select: none;
}

.custom-input input::placeholder {
    font-size: 14px;
    /* 修改字号 */
    color: gray;
}

/** 不显示滚动条 */
.scrollbar-hidden::-webkit-scrollbar,
.scrollbar-hidden *::-webkit-scrollbar {
    display: none;
    /* Chrome, Edge, Safari */
}

.customSkeleton {
    --width: 70%;
    --height: 100px;
    --border-radius: 8px;
}

.custom-error-block .am-error-block-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}